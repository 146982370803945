
<app-hero></app-hero>

<div class="section" role="main">

    <div class="reservations subsection">
        <div class="subsection-col reservations-info">
            <h1>Bookings & Reservations</h1>
            <div class="reservations-info-text">
                <span>For takeaways and deliveries, </span>
                <span>Please call: <a [href]="'tel:' + 02085425688">02085425688</a></span>
                <span>Email: <a href="mailto:hibkk@aol.com">hibkk@aol.com</a></span>
                <span>or book on:</span>
            </div>
            <iframe src="https://widget.quandoo.co.uk/widget/reservation/merchant/pXhTT?sid=2" width="310" height="342" seamless="seamless" scrolling="no" frameborder="0"></iframe>
    
        </div>
        <div class="subsection-col reservations-map">
            <iframe width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=hi%20bangkok%20london&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </div>
    </div>
</div>


<app-footer></app-footer>