<div class="toolbar" role="banner">
    <img
        alt="Hi Bangkok Logo"
        src="./../../assets/images/logo.png"
        routerLink="/"
    />
    <div class="spacer"></div>
    <a class="toolbar-link" aria-label="Menu"  rel="noopener" routerLink="/menu" title="Menu">
        Menu
    </a>
    <a class="toolbar-link toolbar-reservations" aria-label="Reservations"  rel="noopener" routerLink="/reservations" title="Reservations">
        Reservations
    </a>
</div>